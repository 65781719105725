import { render, staticRenderFns } from "./View.vue?vue&type=template&id=5a8a4db4&scoped=true&"
import script from "./View.vue?vue&type=script&lang=js&"
export * from "./View.vue?vue&type=script&lang=js&"
import style0 from "./View.vue?vue&type=style&index=0&id=5a8a4db4&lang=scss&scoped=true&"
import style1 from "./View.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a8a4db4",
  null
  
)

export default component.exports